<template>
  <!-- <component :is="script" src="https://cdn.jsdelivr.net/npm/@tensorflow/tfjs@2.0.0/dist/tf.min.js" async defer></component> -->
  <Connect4> </Connect4>
</template>

<script>
import Connect4 from "../components/Connect4.vue";

export default {
  components: { Connect4 },
  methods: {},
  setup() {
    return { model: null };
  },
  async mounted() {},
};
</script>
